const calculatorVitrinUrls = [
    '/zaimy/onlain/',
    '/zaimy/na-kartu-bez-otkazov/',
    '/zaimy/s-plokhoj-kreditnoj-istoriej/',
    '/zaimy/bez-protsentov/',
    '/zaimy/top/',
    '/zaimy/pod-pts/',
    '/zaimy/cherez-gosuslugi/',
    '/zaimy/na-kartu-do-zarplaty/',
    '/zaimy/dolgosrochnye/',
    '/zaimy/s-prosrochkami/',
];

export const CALCULATOR_VITRIN_URLS_SET = new Set(calculatorVitrinUrls);
