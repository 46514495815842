import type { MouseEvent, MouseEventHandler, MutableRefObject, ReactNode } from 'react';
import React, { useLayoutEffect, useState } from 'react';

import { Button } from '@sravni/react-design-system';
import { useBoolean } from '@sravni/react-utils';

import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';
import type { CommonCardProps } from '@src/@types/commonCardProps';
import type { IFilters } from '@src/@types/microcredits';
import { sendOpenCardDetailsInModalEvent } from '@src/helpers/analyticsEvents';

import { CardDetailsModal } from './components';

interface Props extends CommonCardProps {
    offer: IClientMicrocreditListItem;
    position: number;
    detailModals: MutableRefObject<Record<string, () => void>>;
    query?: Pick<IFilters, 'term' | 'amount'>;
    className?: string;
    afterCard?: ReactNode;
    renderCard: (props: CommonCardProps) => ReactNode;
}

export const CardWithDetailsModal = ({ renderCard, detailModals, ...cardProps }: Props) => {
    const [showModal, setShowModal] = useBoolean(false);

    /**
     * Задача: https://sravni-corp.atlassian.net/browse/MFO-838
     * По задаче требуется добавить в HTML модальные окна без дублирования,
     * а карточки у нас зачастую дублируются
     */
    const [isAddModalInHTML, setIsAddModalInHTML] = useState(false);

    useLayoutEffect(() => {
        if (!detailModals.current[cardProps.offer._id]) {
            detailModals.current[cardProps.offer._id] = setShowModal.on;
            setIsAddModalInHTML(true);
        }
    }, [detailModals, cardProps.offer._id, setShowModal.on]);

    const handleClick = (event: MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();

        const { organization, advertising, name } = cardProps.offer;
        const source = `search|position_${cardProps.position}`;
        const eventLabel = `${organization.name}|${name}|${advertising.offerId}|${source}`;

        sendOpenCardDetailsInModalEvent(eventLabel);
        detailModals.current[cardProps.offer._id]?.();
    };

    return (
        <>
            {renderCard({
                ...cardProps,
                actionButton: (
                    <Button onClick={handleClick} variant="primary" block>
                        Получить займ
                    </Button>
                ),
                afterCard: isAddModalInHTML ? (
                    <CardDetailsModal isOpen={showModal} onClose={setShowModal.off} offer={cardProps.offer} />
                ) : null,
            })}
        </>
    );
};
