import { useRef } from 'react';

import { Experiments } from '@src/constants/abTest';
import { useTestVariant } from '@src/hooks/useTestVariants';

export const useCardsWithModals = () => {
    const { isBVariant } = useTestVariant(Experiments.CARD_WITH_DETAILS_MODAL);

    /**
     * Задача: https://sravni-corp.atlassian.net/browse/MFO-838
     * Здесь находятся функции для открытия модального окна по id карточки,
     * Это нужно чтобы избежать дублирования карточек
     */
    const detailModals = useRef<Record<string, () => void>>({});

    return {
        shouldAddModalInCard: isBVariant,
        detailModals,
    };
};
