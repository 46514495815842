import type { MutableRefObject } from 'react';

import type { CommonCardProps } from '@src/@types/commonCardProps';
import { CardWithDetailsModal } from '@src/components/CardWithDetailsModal';
import Card from '@src/components/ProductList/components/Card';
import { CardV2 } from '@src/components/ProductList/components/CardV2';
import {
    CARD_WITH_DETAILS_MODAL_VITRIN_URLS_SET,
    CARD_WITH_DETAILS_MODAL_VITRIN_URLS_WITHOUT_AB,
} from '@src/constants/cardWithModalVitrinUrls';
import { isNaKartuPage } from '@src/utils/routing';

export const getListPageCardComponent = (
    path: string,
    detailModals: MutableRefObject<Record<string, () => void>>,
    shouldAddModalInCard: boolean,
) => (props: CommonCardProps) => {
    const isNewCardDesign = isNaKartuPage(path);
    const isRouteWithCardModal = CARD_WITH_DETAILS_MODAL_VITRIN_URLS_SET.has(path);
    const isRouteWithCardModalWithoutAB = CARD_WITH_DETAILS_MODAL_VITRIN_URLS_WITHOUT_AB.has(path);

    const CardComponent = isNewCardDesign ? CardV2 : Card;
    const shouldAddModal = (shouldAddModalInCard && isRouteWithCardModal) || isRouteWithCardModalWithoutAB;

    if (shouldAddModal) {
        return (
            <CardWithDetailsModal
                renderCard={(props) => <CardComponent {...props} />}
                {...props}
                detailModals={detailModals}
            />
        );
    }

    return <CardComponent {...props} />;
};
