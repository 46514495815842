import type { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { Layout } from '@sravni/react-design-system';
import { useIsMobile } from '@sravni/react-utils';

import type { Application } from '@src/@types/app';
import BottomBannerContainer from '@src/components/BottomBannerContainer';
import { PageContext } from '@src/config';
import { getPageListData } from '@src/config/getPageListData';
import type { IPageContextConfig } from '@src/config/PageContext';
import { BottomBannerTypes } from '@src/constants/bottomBannerTypes';
import { MODES } from '@src/constants/filters';
import { ListKeys } from '@src/constants/microcredits';
import { Calculator } from '@src/containers/calculator/PageHeader/Calculator';
import { getListPageCardComponent } from '@src/containers/list/helpers';
import { useCardsWithModals } from '@src/containers/list/hooks';
import { PageHeader } from '@src/containers/list/PageHeader';
import ProductList from '@src/containers/list/ProductList';
import { useBestProducts } from '@src/hooks/useBestProducts';
import { useListPage } from '@src/hooks/useListPage';
import { useLoadRestOffers } from '@src/hooks/useLoadRestOffers';
import { getHasUtmLabel, useHasUtmLabel } from '@src/reducers/route';
import { checkIsCalculatorVisible } from '@src/utils/checkIsCalculatorVisible';
import { isServer } from '@src/utils/isServer';

import styles from './styles.module.scss';

const SeoComponents = dynamic(() => import('@src/components/SeoComponents'));

const pageConfig: IPageContextConfig = {
    listKey: ListKeys.LIST,
    source: 'search',
    sortPanelVisible: true,
    seoLinksVisible: true,
    extraFiltersVisible: true,
    cardTermIsVisible: true,
    withBanners: true,
    withInvitationBanner: true,
    withEmailBurner: true,
    withSelectionBanner: true,
    withCardProductLink: true,
    isMainPage: true,
};

const ListPage: NextPage = () => {
    const isMobile = useIsMobile();
    const { filter, seo, pathname } = useListPage(ListKeys.LIST);
    const config = useMemo(() => ({ ...pageConfig, withMonetizationCardLogoClick: isMobile }), [isMobile]);

    const { asPath } = useRouter();
    const [pathWithoutQuery] = asPath.split('?');
    const hasUtmLabel = useHasUtmLabel();

    useLoadRestOffers(ListKeys.LIST);
    useBestProducts();

    const isCalculatorVisible = checkIsCalculatorVisible(pathWithoutQuery) && !hasUtmLabel;
    const { shouldAddModalInCard, detailModals } = useCardsWithModals();

    return (
        <PageContext.Provider value={config}>
            <PageHeader className={styles.page_header} seo={seo} filter={filter} />
            <ProductList
                className={styles.product_list}
                filter={filter}
                pathname={pathname}
                renderCard={getListPageCardComponent(pathWithoutQuery, detailModals, shouldAddModalInCard)}
            />
            {isCalculatorVisible && (
                <Layout className={styles.calculator_layout}>
                    <Calculator hasHeadingBlock filters={filter.filters} />
                </Layout>
            )}
            {seo && <SeoComponents seo={seo} breadcrumbs={seo.breadcrumbs} prefooter={seo.prefooter} />}
            <BottomBannerContainer enabledTypes={[BottomBannerTypes.OUT_OF_PAGE, BottomBannerTypes.SIDEBAR]} />
        </PageContext.Provider>
    );
};

ListPage.getInitialProps = (ctx: Application.ReduxNextPageContext) => {
    if (isServer) return {};

    const hasUtmLabel = getHasUtmLabel(ctx.store.getState());

    return getPageListData(ctx, {
        key: ListKeys.LIST,
        defaultFiltersMode: hasUtmLabel ? MODES.AS_UTM : MODES.DEFAULT,
    });
};

export default ListPage;
